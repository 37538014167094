import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import "normalize.css"
import { BrowserRouter as Router } from "react-router-dom"
import { Plugins } from "@capacitor/core"
import { Colors } from "./constants"
const { StatusBar } = Plugins

StatusBar.setBackgroundColor({ color: Colors.accent.primary.base })

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
)

serviceWorker.register()
