import React from "react"
import { Colors } from "../../constants"
import { asRgbaString } from "../../utils"
import Book from "../../model/book"

interface IProps {
  book: Book
}

export default ({ book }: IProps) => (
  <div className="root">
    <style jsx>{`
      .root {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0px 0px 15px ${asRgbaString(Colors.text.base, 0.05)};
        // border-radius: 10px;
      }
      .image-container {
        padding: 10px;
        width: 100%;
      }
      .image {
        height: 200px;
        width: 100%;
        background-image: url("https://www.buehnebaden.at/de/bildbibliothek/dummy/dummy-junge-buhne-start.jpg");
        background-position: center;
        background-size: cover;
        // border-radius: 10px;
      }
      .title {
        margin-bottom: 10px;
      }
    `}</style>
    <div className="image-container">
      <div className="image"></div>
    </div>
    <div className="title">{book.name}</div>
  </div>
)
