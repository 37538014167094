import React, { ReactNode } from "react"
import { motion } from "framer-motion"
import { Transitions } from "../../constants"

interface IProps {
  children: ReactNode | ReactNode[]
}

export default (props: IProps) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.8 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: Transitions.duration.base }}
  >
    {props.children}
  </motion.div>
)
