const COLORS = {
    ROSE: "#f9a481",
    PINK: "#f18f99",
    MUTED_RED: "#f26c72",
    RED: "#e84f5b",
    POP_RED: "#f52d55",
    PINK_RED: "#c14569",
    DARK_RED: "#ae3723",
    YELLOW: "#fac67e",
    ORANGE: "#f19316",
    GREEN: "#c0e6b4",
    DARK_GREEN: "#7ba76e",
    TEAL: "#35a4ad",
    PORTGAGE: "#8b87f6",
    PURPLE: "#c5acf6",
    DARK_PURPLE: "#5b4db1",
    NOT_SO_WHITE_WHITE: '#fcfcfc',
    BLACK: "#1f1b1b",
}

const Colors = {
    background: {
        base: '#ffffff',
        darker: '#f7f7f7'
    },
    text: {
        light: '#666666',
        lighter: '#282828',
        base: '#212121',
        darker: '#111111'
    },
    accent: {
        primary: {
            base: COLORS.PORTGAGE,
        }
    }
}

const Transitions = {
    duration: {
        shortest: 0.05,
        short: 0.1,
        shorter: 0.3,
        base: 0.5,
        longer: 0.8
    }
}

const Styles = {
    bar: {
        borderRadiusH: '5%',
        borderRadiusV: "30%",
        height: '50px'
    }
}


export { Colors, Transitions, Styles }