import React, { ReactNode } from "react"
import { motion } from "framer-motion"
import { Transitions } from "../../constants"

interface IProps {
  children: ReactNode | ReactNode[]
}

export default (props: IProps) => (
  <motion.div
    whileTap={{ scale: 0.95 }}
    transition={{ duration: Transitions.duration.shortest }}
  >
    {props.children}
  </motion.div>
)
