import React from "react"
import BookView from "../components/book/book-grid"

const dummyData = [
  { name: "Test 1" },
  { name: "Test 2" },
  { name: "Test 3" },
  { name: "Test 4" },
  { name: "Test 5" },
]

export default () => <BookView books={dummyData} />
