import React, { ReactNode } from "react"
import { motion } from "framer-motion"

import { Colors, Transitions } from "../../constants"
import { asRgbaString } from "../../utils"

interface IProps {
  children: ReactNode | ReactNode[]
  active?: boolean
  onClick?(): any
}

const size = "35px"

export default ({ children, active = false, onClick }: IProps) => {
  const color = active ? Colors.accent.primary.base : Colors.text.light
  const opacity = active ? 0.8 : 0.5

  return (
    <div className="root" onClick={onClick}>
      <style jsx>{`
        .root {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
        }

        .icon::selection {
          background: #ffb7b7; /* WebKit/Blink Browsers */
        }

        .icon {
          filter: drop-shadow(0 0 5px ${asRgbaString(color, opacity)});
          height: ${size};
          width: ${size};
          padding: 5px;
          backface-visibility: hidden;
        }
      `}</style>
      <motion.div
        initial={{ fill: Colors.text.light }}
        animate={{ scale: active ? 1.2 : 1, fill: color }}
        transition={{ duration: Transitions.duration.base }}
      >
        <div className="icon">{children}</div>
      </motion.div>
    </div>
  )
}
