import React, { ReactNode } from "react"
import { Colors, Styles } from "../../constants"
import { asRgbaString } from "../../utils"

interface IProps {
  children: ReactNode[]
}

export default (props: IProps) => (
  <div className="Navbar">
    <style jsx>{`
      .Navbar {
        display: flex;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: ${Colors.background.darker};
        border-radius: ${Styles.bar.borderRadiusH} ${Styles.bar.borderRadiusH} 0
          0 / ${Styles.bar.borderRadiusV} ${Styles.bar.borderRadiusV} 0 0;
        box-shadow: 0px 0px 10px ${asRgbaString(Colors.text.base, 0.1)};
      }
      .item {
          display: flex;
          justify-content: center;
          align-items: center;
        width: ${100 / props.children.length}%;
        height: 100%;
      }
    `}</style>
    {props.children.map((val, idx) => (
      <div className="item" key={idx}>
        {val}
      </div>
    ))}
  </div>
)
