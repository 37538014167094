import React from "react"
import BookItem from "./book-item"
import Appear from "../animations/appear"
import Tappable from "../animations/tappable"
import Book from "../../model/book"

interface IProps {
  books: Book[]
}

export default ({ books }: IProps) => (
  <div className="root">
    <style jsx>{`
      .root {
      }
      .grid-container {
        padding: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
      }
    `}</style>
    <div className="grid-container">
      {books.map((val, idx) => (
        <Tappable>
          <Appear>
            <BookItem book={val} />
          </Appear>
        </Tappable>
      ))}
    </div>
  </div>
)
